import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "./ModalContext";
import "./Modals.scss";
import "./ModalsResponsive.scss";
import { useTranslation } from "react-i18next";
import { URL } from "../../services/conection";
import { useForm } from "react-hook-form";
import MayanInput from "../Input/MayanInput";
import { SubmitArrowButton } from "../SubmitArrowButton";
import { ReactComponent as Cross } from "../../assets/svgs/cross.svg";
import { useNavigate } from "react-router-dom";

const LoginModal = () => {
  const { modalIsVisible, setModalIsVisible } = useContext(ModalContext);
  const [ errorMessage, setErrorMessage ] = useState("");
  const [showAlert, setShowAlert] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  // let navigate = useNavigate();

  const { t } = useTranslation();

  const onSubmit = async(data) => {
    const { UserName, Password } = data;
    //console.log(Password, UserName)

    let object = {
      UserName:UserName.trim(),
      Password,
    }

    let req = await fetch(`${URL}api/user/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    });

    let resp = await req.json();

    if (resp.code === 200 && resp.url) {
      setShowAlert(false)
      window.location.href = resp.url
    }else{
      setShowAlert(true)
      setErrorMessage(resp.msg)
    }

  };

  useEffect(() => {
    const body = document.querySelector("body");

    if (modalIsVisible) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  });

  return (
    <div
      className="mayan-modal-full-screen-ctnr"
      style={{ display: modalIsVisible ? "flex" : "none" }}
    >
      <div className="mayan-modal">
        <div className="mayan-modal-body">
          <button
            className="close-btn"
            onClick={() => {setModalIsVisible(!modalIsVisible), setShowAlert(false)}}
          >
            {" "}
            <Cross />{" "}
          </button>
          <div className="mayan-modal-header">Login</div>
          <div className="mayan-modal-content">
            <form
              className="mayan-form mayan-form--black"
              onSubmit={handleSubmit(onSubmit)}
            >
              <MayanInput
                id="UserName"
                name="UserName"
                type="text"
                text={t("modals.login.username")}
                label="UserName"
                register={register}
              />

              <MayanInput
                id="Password"
                name="Password"
                type="password"
                text={t("modals.login.password")}
                label="Password"
                register={register}
                required
                errorHandler={errors.Password}
                errorMessage="This field is required"
              />

              <SubmitArrowButton text="Login" id="Submit" name="Submit" wFull />
            </form>
          </div>
          {showAlert && <span className="errorMesssage errorMesssage--response"> {errorMessage} </span>}
          <div className="mayan-modal-actions">
            {/* <Link className="link" to={"https://hola.com"}>No account yet? click here for sign up</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
