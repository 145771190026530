import React, { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar/index";
import Footer from './components/Footer';
import LoginModal from './components/Modals/LoginModal';
import { useTranslation} from 'react-i18next';

const App = () => {

  const [shouldShrink, setShouldShrink] = useState(false);
  const userLang = window.navigator.userLanguage || window.navigator.language;

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > 200) {
      setShouldShrink(true)
    } else {
      setShouldShrink(false)
    }
  });

  const { t, i18n } = useTranslation();

  let lng = userLang.slice(0,2);

  const userLng = () => {
    let i18nextLng = localStorage.getItem("i18nextLng");

    if(i18nextLng === "en" || i18nextLng === "es"){
      i18n.changeLanguage(i18nextLng);
      lng = i18nextLng;
    }else{
      localStorage.setItem("i18nextLng", userLang.slice(0,2));
      i18n.changeLanguage(userLang.slice(0,2));
      lng = userLang.slice(0,2);
    }
  }

  useEffect(() => {
    userLng();
  }, [])

  return (
    <>
        <Navbar
          shouldShrink={shouldShrink}
        />
        <LoginModal />
        <Outlet />
        <Footer userLanguage={lng}/>
    </>
  );
}

export default App;
