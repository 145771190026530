import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import SmartPhoneEs from "../../assets/images/phone-es.webp";
import SmartPhoneEn from "../../assets/images/phone.webp";
import { ReactComponent as ArrowRight } from "../../assets/svgs/arrow-right.svg";
import WatermarkLogo from "../../assets/svgs/mayan-logo-watermark.svg";
import { useTranslation } from 'react-i18next';


const EarlyAccess = () => {

  const { t } = useTranslation();

  return (
    <section className="earlyAccess" id="early">
      <div className="earlyAccess__ctnr">
        <div className="earlyAccess__data-box">
          <h2 className="headerTitle">
            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
              <b>
                {t('earlyAccessSection.title')}
                {/* Early Access */}
              </b>
            </ScrollAnimation>
          </h2>
          <br />
            <ScrollAnimation className="paragraph" animateIn="animate__animated animate__fadeInUp">
          <p>
              {t('earlyAccessSection.subtitle')}
              {/* Be the first to participate in our beta and more */}
          </p>
            </ScrollAnimation>
          <br />
          <form className="earlyAccess__form">
            <label>
              {t('earlyAccessSection.form.name')}
              {/* Name */}
              <input id="Name" name="Name" type="text" />
            </label>
            <label id="Email" name="Email">
              {t('earlyAccessSection.form.email')}
              {/* Email */}
              <input type="email" />
            </label>
            <br />
            <br />
            <br />
            <ScrollAnimation animateIn="animate__animated animate__fadeInUp" target="_blank" href="https://send.mayan.cash/home" className="btn btn-mayan btn-mayan--green" rel="noreferrer">
              <p>
                {t('earlyAccessSection.form.submit_button')}
                {/* Empecemos */}
              </p>
              <ArrowRight className='arrowRight' />
            </ScrollAnimation>
          </form>
        </div>
        <div className="earlyAccess__img-box">
          <img src={localStorage.getItem('i18nextLng') === "es" ? SmartPhoneEs : SmartPhoneEn} alt="" />
        </div>
      </div>
      <img className="earlyAccess__overflowImage" src={WatermarkLogo} alt="" />
    </section>
  );
};

export default EarlyAccess;
