import React from "react";
import {
  useNavigate,
} from "react-router-dom";


const TransitionLink = ({ children, to, hash, className, setTransition, transitionDuration=1000, ...props }) => {
    let pathAndHash = hash ? to+hash : to;
    let navigate = useNavigate();

    const clearAllTimeOuts = (hash) => {
        setTimeout(() => {
          setTransition(false)
          if(hash) window.location.hash = hash;
        }, transitionDuration*0.4);
      }
    
    const navigator = (dir, hash) => {
        if (window.location.pathname !== dir) {
            setTransition(true)
            setTimeout(() => {
                navigate(dir)
                clearAllTimeOuts(hash);
            }, transitionDuration*0.6);
        }
        if (((window.location.pathname === dir) && hash) &&window.location.hash !== hash) {
            window.location.hash = hash
        }
    }

    return (
        <a
        className={`${className} ${window.location.pathname + window.location.hash === pathAndHash ? "linksTxt--active" : ""}`}
        onClick={()=> navigator(to, hash)}
          {...props}
        >
          {children}
        </a>
    );
  }

  export default TransitionLink;