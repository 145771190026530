import React, { useState } from "react";
import {useTranslation} from "react-i18next";

const Question = ({ i18nKeyQuestion, i18nKeyAnswer }) => {

    const [toggle, setToggle] = useState(false);

    const { t } = useTranslation();

    return (
      <div className="FAQ__question-ctnr" onClick={() => setToggle(!toggle)}>
        <div className={`FAQ__question ${toggle ? "FAQ__question--active" : ""}`}>
          <p className="linksTxt">
          {t(i18nKeyQuestion)}
          </p>
        </div>
        <div className="FAQ__answer" aria-expanded={!toggle}>
          <p className="paragraph u-txt-center">{t(i18nKeyAnswer)}</p>
        </div>
      </div>
    );
  };


  export default Question;