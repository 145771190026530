import Question from "./Question";

const FAQ = () => {
  
  return (
    <section className="FAQ">
      <div className="FAQ__ctnr">
        <div className="FAQ__txt-box">
          <h2 className="headerTitle u-txt-center">
            <b>FAQ</b>
          </h2>
        </div>
        <Question
          i18nKeyQuestion="FAQ.questions.q1"
          i18nKeyAnswer="FAQ.answers.answ1"
        />
      </div>
    </section>
  );
};

export default FAQ;
