import React from 'react';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import BackgroundVideoEs from '../../assets/videos/hero-video.mp4';
import BackgroundVideoEn from '../../assets/videos/hero-video-en.mp4';
import BackgroundVideoMobileEs from '../../assets/videos/hero-video-mobile.mp4';
import BackgroundVideoMobileEn from '../../assets/videos/hero-video-mobile-en.mp4';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <header className="hero">
      <video
        className="hero__bg hero__bg--desktop"
        autoPlay
        loop
        muted
        id="video"
        key={localStorage.getItem('i18nextLng') !== "en" ? BackgroundVideoEs : BackgroundVideoEn}
      >
        <source src={localStorage.getItem('i18nextLng')!== "en" ? BackgroundVideoEs : BackgroundVideoEn} />
      </video>
      <video
        className="hero__bg hero__bg--mobile"
        autoPlay
        loop
        muted
        id="video"
        key={localStorage.getItem('i18nextLng') !== "en" ? BackgroundVideoMobileEs : BackgroundVideoMobileEn}
      >
        <source src={localStorage.getItem('i18nextLng') !== "en" ? BackgroundVideoMobileEs : BackgroundVideoMobileEn} />
      </video>
      <ScrollAnimation animateIn="animate__animated animate__fadeIn" className="hero__txt-box">
        <h1 className="hugeTxt">
          <span className="hugeTxt--dot">
            {t('heroText.word1')}
            {/* Transfer */}
          </span>
          <span>
            {t('heroText.word2')}
            {/* Money */}
          </span>
          <br />
          <span className="hugeTxt--dot">
            {t('heroText.word3')}
            {/* To/In Mexico */}
          </span>
          <span>
            {t('heroText.word4')}
            {/* Easy */}
          </span>
          <br />
          <span className="hugeTxt--dot">
            {t('heroText.word5')}
            {/* Fast */}
          </span>
          <span>
            {t('heroText.word6')}
            {/* Any time */}
          </span>
        </h1>
      </ScrollAnimation>
    </header>
  );
}

export default Hero
