import React from "react";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FrequentlyAskedQuestions from "./containers/FAQ";
import AboutUs from "./containers/About";
import Home from "./containers/Home";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="FAQ" element={<FrequentlyAskedQuestions />} />
          <Route path="About" element={<AboutUs />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
