import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import mom from "../../assets/images/mom-latin.webp"
import menEn from "../../assets/images/man-mid-latin.png"
import menES from "../../assets/images/man-mid-latin-es.png"
import maEn from "../../assets/images/ma-mid-latin.png"
import maES from "../../assets/images/ma-mid-latin-es.png"
import handEs from "../../assets/images/hand-mayan.webp"
import handEn from "../../assets/images/hand-mayan-en.webp"
import {useTranslation} from "react-i18next";

const ImageBox = ({img, bgColor,i18nKeyTitle, i18nKeyTagline}) => {

  const { t } = useTranslation();

  return (<div className="HIW__img-box" style={{
    backgroundColor: `${bgColor}`
  }}>
    <div className="HIW__txt-box">
      <ScrollAnimation animateIn="animate__animated animate__fadeInUp" className="headerTitle">
        <b>
        {t(i18nKeyTitle)}
        </b>
      </ScrollAnimation>
      <br />
      <ScrollAnimation animateIn="animate__animated animate__fadeInUp" className="paragraph">
        {t(i18nKeyTagline)}
      </ScrollAnimation>
    </div>
    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
    <img src={img} alt="" />
    </ScrollAnimation>
  </div>);
}


const HowItWorksSection = () => {

  const { t } = useTranslation();

  return (
    <section className="HIW" id="hiw">
      <div className="HIW__imgs-ctnr">
        <ImageBox
        // Envia en todo momento
        i18nKeyTitle="howItWorksSection.imageBox2.title"
        // Sin ir al banco hacer filas o esperar un dia para que llegue
        i18nKeyTagline="howItWorksSection.imageBox2.tagline"
        img={localStorage.getItem('i18nextLng')==="en" ? menEn : menES}
        bgColor="#99E6CF"
        />
        <ImageBox
        // Recibe en cuestion de minutos
        i18nKeyTitle="howItWorksSection.imageBox1.title"
        // Directo a tu cuenta y sin retrasos
        i18nKeyTagline="howItWorksSection.imageBox1.tagline"
        img={localStorage.getItem('i18nextLng')==="en" ? maEn : maES}
        bgColor="#EEE099"
        />
        <ImageBox
        // A lo que vas
        i18nKeyTitle="howItWorksSection.imageBox3.title"
        // Facil de usar, interfaz intuitiva y sin distracciones
        i18nKeyTagline="howItWorksSection.imageBox3.tagline"
        img={localStorage.getItem('i18nextLng')==="en" ? handEn : handEs}
        bgColor="#FBFBFB"
        />
      </div>
    </section>
  );
};

export default HowItWorksSection;
