import React from "react";
import "./MayanInput.scss";
const MayanInput = ({ label, register, required, id, name, type = "text", text, errorHandler, errorMessage}) => {
  return (
    <label className={`${errorHandler ? "mayan-label mayan-label--error" : "mayan-label"}`}>
      {text}
      <input {...register(label, { required })} className={`${errorHandler ? "mayan-input mayan-input--error" : "mayan-input mayan-input--dark"}`} id={id} name={name} type={type} />
      {errorHandler && <span className="errorMesssage">{errorMessage}</span>}
    </label>
  );
};

export default MayanInput;
