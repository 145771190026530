import React, { useContext, useState, useEffect } from "react";
import { ModalContext } from "../../components/Modals/ModalContext";
import ScrollAnimation from "react-animate-on-scroll";
import { ReactComponent as ArrowRight } from "../../assets/svgs/arrow-right.svg";
import { useTranslation } from "react-i18next";
import { SubmitArrowButton } from "../../components/SubmitArrowButton";
import badge10 from "../../assets/images/badge-10.png";
import badge50 from "../../assets/images/badge-50.png";
import badge100 from "../../assets/images/badge-100.png";
import badgeCard from "../../assets/images/badge-card.png";
import badgeEmpty from "../../assets/images/badge-empty.png";

const WebApp = () => {
  const { t } = useTranslation();
  const { modalIsVisible, setModalIsVisible } = useContext(ModalContext);
  const defaultPrice = [
    {
        name: "Pesos",
        code: "MXN",
        amount: 180.9,
        originalAmount: 10,
        marketPrice: 18.09
    },
    {
        name: "Pesos",
        code: "MXN",
        amount: 452.25,
        originalAmount: 25,
        marketPrice: 18.09
    },
    {
        name: "Pesos",
        code: "MXN",
        amount: 904.5,
        originalAmount: 50,
        marketPrice: 18.09
    },
    {
        name: "Pesos",
        code: "MXN",
        amount: 1809,
        originalAmount: 100,
        marketPrice: 18.09
    },
    {
        name: "Pesos",
        code: "MXN",
        amount: 3618,
        originalAmount: 200,
        marketPrice: 18.09
    },
    {
        name: "Pesos",
        code: "MXN",
        amount: 5427,
        originalAmount: 300,
        marketPrice: 18.09
    }
]
  const [priceDefault, setPriceDefault] = useState(defaultPrice)

  useEffect(() => {
    fetch("https://mayaapp.dcsysinteractive.com/mayan/buy-prices")
    .then(resp => resp.json())
    .then(prices =>  setPriceDefault(prices))
    .catch(error => setPriceDefault(defaultPrice));
  }, [])
  

  return (
    <div className="webapp">
      <div className="webapp__txt-box">
        <h2 className="headerTitle">
          <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
            <b>
              {t("webAppSection.title")}
              {/* Prueba nuestra web app */}
            </b>
          </ScrollAnimation>
        </h2>
        <ScrollAnimation
          animateIn="animate__animated animate__fadeInUp"
          className="paragraph"
        >
          {t("webAppSection.subtitle")}
          {/* Ya estamos listos para transferir tu dinero */}
        </ScrollAnimation>
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
          <div className="alineador">
            <SubmitArrowButton
              onClick={() => setModalIsVisible(!modalIsVisible)}
              text={t("webAppSection.button")}
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="webapp__badges-ctnr">
        <div className="webapp__badges webapp__badges--badge10">
          <div className="webapp__badges-badge-content">
            <p className="webapp__badges-badge-content--right-desktop">${priceDefault[0].originalAmount}</p>
            <p className="webapp__badges-badge-content--left-desktop">
              {priceDefault[0].amount}&nbsp;<span>{priceDefault[0].code}</span>
            </p>
          </div>
          <img width="100%" src={badge10} alt="badge10" />
        </div>
        <div className="webapp__badges webapp__badges--badge50">
          <div className="webapp__badges-badge-content">
            <p className="webapp__badges-badge-content--right-desktop">${priceDefault[2].originalAmount}</p>
            <p className="webapp__badges-badge-content--left-desktop">
              {priceDefault[2].amount}&nbsp;<span>{priceDefault[2].code}</span>
            </p>
          </div>
          <img width="100%" src={badge50} alt="badge50" />
        </div>
        <div className="webapp__badges webapp__badges--badge100">
          <div className="webapp__badges-badge-content">
            <p className="webapp__badges-badge-content--right-desktop">${priceDefault[3].originalAmount}</p>
            <p className="webapp__badges-badge-content--left-desktop">
              {priceDefault[3].amount}&nbsp;<span>{priceDefault[3].code}</span>
            </p>
          </div>
          <img width="100%" src={badge100} alt="badge100" />
        </div>
        <div className="webapp__badges webapp__badges--badgeCard">
          <div className="webapp__badges-badge-content webapp__badges-badge-content--column">
            <p className="webapp__badges-badge-content--full-top">
              1.00<span>USD</span>
            </p>
            <p className="webapp__badges-badge-content--full-bottom">
            {priceDefault[0].marketPrice} MXN
            </p>
          </div>
          <img width="100%" src={badgeCard} alt="badgeCard" />
        </div>
      </div>
      <div className="webapp__badges-grid">
        <div className="webapp__badges--mobile">
          <div className="webapp__badges-badge-content">
            <p className="webapp__badges-badge-content--right">${priceDefault[0].originalAmount}</p>
            <p className="webapp__badges-badge-content--left">
              {priceDefault[0].amount}&nbsp;<span>MXN</span>
            </p>
          </div>
          <img width="100%" src={badgeEmpty} alt="badgeEmpty" />
        </div>
        <div className="webapp__badges--mobile">
          <div className="webapp__badges-badge-content">
            <p className="webapp__badges-badge-content--right">${priceDefault[1].originalAmount}</p>
            <p className="webapp__badges-badge-content--left">
              {priceDefault[1].amount}&nbsp;<span>MXN</span>
            </p>
          </div>
          <img width="100%" src={badgeEmpty} alt="badgeEmpty" />
        </div>
        <div className="webapp__badges--mobile">
          <div className="webapp__badges-badge-content">
            <p className="webapp__badges-badge-content--right">${priceDefault[2].originalAmount}</p>
            <p className="webapp__badges-badge-content--left">
              {priceDefault[2].amount}&nbsp;<span>MXN</span>
            </p>
          </div>
          <img width="100%" src={badgeEmpty} alt="badgeEmpty" />
        </div>
        <div className="webapp__badges--mobile">
          <div className="webapp__badges-badge-content">
            <p className="webapp__badges-badge-content--right">${priceDefault[3].originalAmount}</p>
            <p className="webapp__badges-badge-content--left">
              {priceDefault[3].amount}&nbsp;<span>MXN</span>
            </p>
          </div>
          <img width="100%" src={badgeEmpty} alt="badgeEmpty" />
        </div>
      </div>
    </div>
  );
};

export default WebApp;
