import React, { useState } from "react";
import "./Footer.scss";
import "./FooterResponsive.scss";
import LOGO from "../../assets/svgs/logo.svg";
import { ReactComponent as LinkedInLogo } from "../../assets/svgs/linkedin.svg";
import { ReactComponent as FacebookLogo } from "../../assets/svgs/facebook.svg";
import { ReactComponent as TwitterLogo } from "../../assets/svgs/twitter.svg";
import { ReactComponent as YoutubeLogo } from "../../assets/svgs/youtube.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as Chevron } from "../../assets/svgs/chevron-down.svg"
import USAFlag from "../../assets/images/USA.png"
import MXFlag from "../../assets/images/MX.png"




const LanguageModal = (props) => {
  return (<div className={`footer__language ${props.modalIsOpen ? "footer__language--active" : "footer__language--inactive"}`}>
    <div className={`footer__language-option ${localStorage.getItem('i18nextLng') === "es" ? "footer__language-option--active" : ""}`} onClick={() => props.changeCurrentLng("es")}>
      <p className="paragraph">
        <img src={MXFlag} alt="" />
        {props.t('footer.language.spanish')}
        {
          /* Spanish */
        }
      </p>
    </div>
    <div className={`footer__language-option ${localStorage.getItem('i18nextLng') === "en" || localStorage.getItem('i18nextLng') !== "es" ? "footer__language-option--active" : ""}`} onClick={() => props.changeCurrentLng("en")}>
      <p className="paragraph">
        <img src={USAFlag} alt="" />
        {props.t('footer.language.english')}
        {
          /* English */
        }
      </p>
    </div>
  </div>);
}


const Footer = ({ userLanguage }) => {

  const { t, i18n } = useTranslation();

  const lngs = {
    en: { nativeName: 'footer.language.english' },
    es: { nativeName: 'footer.language.spanish' }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userLng, setUserLng] =  useState(t(lngs[i18n.resolvedLanguage].nativeName));

  const openModal = () => {
    setModalIsOpen(!modalIsOpen)
  }

  const changeCurrentLng = (lng) => {
    i18n.changeLanguage(lng)
    setUserLng(t(lngs[lng].nativeName))
  }

  return (
    <div className="footer">
      <div className="footer__ctnr">
        <div className="footer__left-content">
          <img src={LOGO} alt="" />
        </div>
        <div className="footer__right-content footer__right-content--position-end">
          <a className="linksTxt u-txt-center u-txt-white" href="#none">
            {t("footer.links.link1")}
            {/* Privacy */}
          </a>
          <a className="linksTxt u-txt-white" href="#none">
            {t("footer.links.link2")}
            {/* Terms & conditions */}
          </a>
          <div style={{position: "relative"}}>
          <p
            className={`linksTxt u-txt-white ${modalIsOpen ? "active" : ""}`}
            onClick={openModal}
          >
            {userLng}
            <Chevron />
          </p>
            <LanguageModal
              modalIsOpen={modalIsOpen}
              t={t}
              changeCurrentLng={changeCurrentLng}
              lngs={lngs}
            />
          </div>
        </div>
        <div className="footer__middle-content">
          <p className="linksTxt u-txt-center u-txt-white">
            {t("footer.title")}
            {/* We’re social, follow us */}
          </p>
          <div className="footer__social-media">
            <a href="https://twitter.com/mayancash">
              <TwitterLogo />
            </a>
            <a href="https://www.facebook.com/mayancash">
              <FacebookLogo />
            </a>
            <a href="https://www.linkedin.com/company/mayancash">
              <LinkedInLogo />
            </a>
            <a href="https://www.youtube.com/channel/UC27pHjKgwfOW3lv-CUMPRtQ">
              <YoutubeLogo />
            </a>
          </div>
          <p className="paragraph paragraph--feature u-txt-center u-txt-white u-txt-copyright">
            {t("footer.copyright")}
            {/* Copyright © 2021 Mayan Cash. All rights reserved */}
          </p>
        </div>
        <div className="footer__right-content footer__right-content--position-initial">
          <a className="linksTxt u-txt-center u-txt-white" href="#none">
            {t("footer.links.link1")}
            {/* Privacy */}
          </a>
          <a className="linksTxt u-txt-white" href="#none">
            {t("footer.links.link2")}
            {/* Terms & conditions */}
          </a>
          <div style={{position: "relative"}}>
          <p
            className={`linksTxt u-txt-white ${modalIsOpen ? "active" : ""}`}
            onClick={openModal}
          >
            {userLng}
            <Chevron />
          </p>
            <LanguageModal
              modalIsOpen={modalIsOpen}
              t={t}
              changeCurrentLng={changeCurrentLng}
              lngs={lngs}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
