import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Navbar.scss";
import "./NavbarResponsive.scss";
import { ReactComponent as MayanLogo } from "../../assets/svgs/logo.svg";
import { ReactComponent as ArrowRight } from "../../assets/svgs/arrow-right.svg";
import { ReactComponent as Hamburger } from "../../assets/svgs/circle-outline.svg";

import ScrollRestoring from "../ScrollToTop";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import TransitionLink from "../TransitionLink";

const Navbar = ({ shouldShrink }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [transition, setTransition] = useState(false);

  const {t} = useTranslation()

  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navbarShrinked = location.pathname !== "/" ? true : shouldShrink;

  const linkColor = navbarShrinked ? "#000000" : "#ffffff";

  return (
    <>
    <div className={`curtains ${transition ? "curtains--active" : ""}`}>
    <div className={`curtain curtain--1 ${transition ? "curtain--active" : ""}`}></div>
    <div className={`curtain curtain--2 ${transition ? "curtain--active" : ""}`}></div>
    </div>
      <ScrollRestoring />
      <nav
        className="navbar"
        style={{
          backgroundColor: navbarShrinked ? "white" : "transparent",
          boxShadow: navbarShrinked
            ? `0px 80px 123px rgba(0, 0, 0, 0.03), 0px 40.0391px 61.5601px rgba(0, 0, 0, 0.02281), 0px 24.1177px 37.0809px rgba(0, 0, 0, 0.0195477), 0px 15.4559px 23.7634px rgba(0, 0, 0, 0.0171268), 0px 10.0172px 15.4015px rgba(0, 0, 0, 0.015), 0px 6.30574px 9.69508px rgba(0, 0, 0, 0.0128732), 0px 3.62304px 5.57042px rgba(0, 0, 0, 0.0104523), 0px 1.59459px 2.45169px rgba(0, 0, 0, 0.00719002)`
            : "none",
        }}
      >
        <div className="navbar__ctnr">
          <div
            className={`navbar__logo-ctnr ${navbarShrinked ? "navbar__logo-ctnr--shrinked" : ""
              }`}
            onClick={() =>
              location.pathname === "/" ? scrollToTop() : null
            }
          >
            <TransitionLink
              className="linksTxt"
              to="/"
              style={{ color: linkColor }}
              setTransition={setTransition}
            >
              <MayanLogo />
            </TransitionLink>
          </div>
          <div
            className={`navbar__links ${
              navbarShrinked ? "navbar__links--shrinked" : ""
            }`}
          >
            <ul>
              <li>
                <TransitionLink
                  className="linksTxt"
                  to="/"
                  hash="#hiw"
                  style={{ color: linkColor }}
                  setTransition={setTransition}
                >
                  {t('navBarLinks.how_it_works')}{/* How it works */}
                </TransitionLink>
              </li>
              <li>
                <TransitionLink
                  className="linksTxt"
                  to="/"
                  hash="#features"
                  style={{ color: linkColor }}
                  setTransition={setTransition}
                >
                  {t('navBarLinks.features')}{/* Features */}
                </TransitionLink>
              </li>
              <li>
                <TransitionLink
                  className="linksTxt"
                  to="/"
                  hash="#security"
                  style={{ color: linkColor }}
                  setTransition={setTransition}
                >
                  {t('navBarLinks.security')}{/* Security */}
                </TransitionLink>
              </li>
              <li>
                <TransitionLink
                  className="linksTxt"
                  style={{ color: linkColor }}
                  to="/FAQ"
                  setTransition={setTransition}
                >
                  {t('navBarLinks.frequently_asked_question')}{/* FAQ */}
                </TransitionLink>
              </li>

              <li>
                <TransitionLink
                  to="/"
                  hash="#early"
                  style={{
                    borderRadius: "1.8rem",
                    margin: "0",
                    display: "inline-flex",
                    overflow: "hidden",
                  }}
                  setTransition={setTransition}
                >
                  <button className="btn btn--nav-link">
                  {t('navBarLinks.early_access')}{/* Early Access */}
                    <ArrowRight className="arrowRight" />
                  </button>
                </TransitionLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        className={`navbar__hamburger ${
          navbarShrinked ? "navbar__hamburger--shrinked" : ""
        } ${menuOpen ? "navbar__hamburger--active" : ""}`}
        onClick={openMenu}
      >
        <Hamburger />
      </div>
      <div className={`navbar__menu ${menuOpen ? "navbar__menu--opened" : ""}`}>
        <div className="navbar__links">
          <ul>
            <li onClick={openMenu}>
              <HashLink className="linksTxt u-txt-white" to="/#hiw">
              {t('navBarLinks.how_it_works')}{/* How it works */}
              </HashLink>
            </li>
            <li onClick={openMenu}>
              <HashLink className="linksTxt u-txt-white" to="/#features">
              {t('navBarLinks.features')}{/* Features */}
              </HashLink>
            </li>
            <li onClick={openMenu}>
              <HashLink className="linksTxt u-txt-white" to="/#security">
              {t('navBarLinks.security')}{/* Security */}
              </HashLink>
            </li>
            <li onClick={openMenu}>
              <Link className="linksTxt u-txt-white" to="/FAQ">
              {t('navBarLinks.frequently_asked_question')}{/* FAQ */}
              </Link>
            </li>
            <li onClick={openMenu}>
              <Link className="linksTxt u-txt-white" to="/about">
              {t('navBarLinks.about_us')}{/* About Us */}
              </Link>
            </li>
            <li onClick={openMenu}>
              <HashLink
                to="/#early"
                style={{
                  borderRadius: "1000px",
                  margin: "0",
                  display: "inline-flex",
                  overflow: "hidden",
                }}
              >
                <button className="btn btn--nav-link">
                {t('navBarLinks.early_access')}{/* Early Access */}
                  <ArrowRight className="arrowRight" />
                </button>
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
