import React from 'react'
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import "./About.scss";
import "./AboutResponsive.scss";
import MetaTags from "react-meta-tags";
import MetaHomeImg from "../../assets/meta/mayan.png";
import EdPicture from "../../assets/images/Ed.png"
import DavePicture from "../../assets/images/Dave.png"
import MarioPicture from "../../assets/images/Mario.png"
import LeviPicture from "../../assets/images/Levi.png"



function MayanCard({ title, children, superminimal, to }) {
    return (
        to ? (
            <Link className={`mayan-card ${superminimal ? "mayan-card--superminimal" : ""}`} to={to} offset={-150}>
        <h4 className="mayan-card-title">
            {title}
        </h4>
        <div className="mayan-card-content">
            {children}
        </div>
    </Link>
        ):(
            <div className={`mayan-card ${superminimal ? "mayan-card--superminimal" : ""}`} offset={-150}>
        <h4 className="mayan-card-title">
            {title}
        </h4>
        <div className="mayan-card-content">
            {children}
        </div>
    </div>
        )
    );
}



function CardPeople({picture,name, job,description}) {
    return (<div className="aboutUs__people-card">
        <div className="aboutUs__people-img-box">
            <img src={picture} alt="" />
        </div>
        <div className="aboutUs__people-txt-box">
            <h4 className='headerTitle'><b>{name} - </b>{job}</h4>
            <p className="paragraph">
            {description}
            </p>
        </div>
    </div>);
}


const AboutUs = () => {

    const { t } = useTranslation();

    return (
        <>
            <MetaTags>
                <title>Mayan</title>
                <meta property="og:type" content="website" />
                <meta property="og:image" content={MetaHomeImg} />
                <meta property="og:title" content="Mayan - Transfer Money" />
                <meta
                    name="description"
                    content="Transfer Money To/In Mexico Easy Fast Anytime"
                />
                <meta property="og:url" content="https://www.mayan.cash/" />
                <meta property="og:site_name" content="Mayan.cash" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@theRealMayan" />
                <meta name="twitter:creator" content="@theRealMayan" />
                <meta name="twitter:title" content="Mayan - Transfer Money" />
                <meta
                    name="twitter:description"
                    content="Transfer Money To/In Mexico Easy Fast Anytime"
                />
                <meta name="twitter:image" content={MetaHomeImg} />
            </MetaTags>
            <div className="aboutUs">
                <div className="aboutUs__ctnr">
                    <div className="aboutUs__title-box">
                        <h2 className='headerTitle'>{t("about.header")}</h2>
                    </div>

                    <div className="aboutUs__values-cards-ctnr">
                        <MayanCard title={t("about.cards.card1.title")} to="approach">
                            <p className="paragraph">
                                {t("about.cards.card1.content")}
                            </p>
                        </MayanCard>
                        <MayanCard title={t("about.cards.card2.title")} to="team">
                            <p className="paragraph">
                                {t("about.cards.card2.content")}
                            </p>
                        </MayanCard>
                        <MayanCard title={t("about.cards.card3.title")} to="values">
                            <p className="paragraph">
                                {t("about.cards.card3.content")}
                            </p>
                        </MayanCard>
                    </div>

                    <div id='approach' className="aboutUs__values-description">
                        <h3 className='headerTitle'>{t("about.sectionAproach.title")}</h3>
                        <p className='paragraph'>{t("about.sectionAproach.content")}</p>
                    </div>
                    <div id='team' className="aboutUs__values-description">
                        <h3 className='headerTitle'>{t("about.sectionTeam.title")}</h3>
                        <p className='paragraph'>{t("about.sectionTeam.content")}</p>
                    </div>
                    <div className="aboutUs__people-ctnr">
                        <CardPeople
                            picture={EdPicture}
                            name="Eduardo Del Rio"
                            job="CEO"
                            description="Software and hardware engineer with 15 years of experience,   Business entrepreneur with 2 provisional patents."
                        />
                        <CardPeople
                            picture={DavePicture}
                            name="David Canar"
                            job="CTO"
                            description="Software engineer with more than 15 years of experience. Founder and CEO of DCSys Tech, a small outsourcing company in Ecuador."
                        />
                        <CardPeople
                            picture={MarioPicture}
                            name="Mario Castellano"
                            job="CFO and PR"
                            description="Entrepreneur and experienced accounting professional who has spent most of his career at Tesla Inc in various finance, accounting and reporting roles."
                        />
                        <CardPeople
                            picture={LeviPicture}
                            name="Levi Del Rio"
                            job="Director of Engineering"
                            description="Entrepreneur and experienced accounting professional who has spent most of his career at Tesla Inc in various finance, accounting and reporting roles."
                        />
                    </div>
                    <div id="values" className="aboutUs__values-description">
                        <h3 className='headerTitle'>{t("about.sectionValues.title")}</h3>
                        <div className="aboutUs__values-values-ctnr">
                            <MayanCard title={t("about.sectionValues.content.value1.title")} superminimal>
                                <p className="paragraph">
                                {t("about.sectionValues.content.value1.description")}
                                </p>
                            </MayanCard>
                            <MayanCard title={t("about.sectionValues.content.value2.title")} superminimal>
                                <p className="paragraph">
                                {t("about.sectionValues.content.value2.description")}
                                </p>
                            </MayanCard>
                            <MayanCard title={t("about.sectionValues.content.value3.title")} superminimal>
                                <p className="paragraph">
                                {t("about.sectionValues.content.value3.description")}
                                </p>
                            </MayanCard>
                            <MayanCard title={t("about.sectionValues.content.value4.title")} superminimal>
                                <p className="paragraph">
                                {t("about.sectionValues.content.value4.description")}
                                </p>
                            </MayanCard>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;
