import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import lockIcon from "../../assets/images/lock-icon.svg";
import {useTranslation} from "react-i18next";

function Badge({ delay, i18nKeyText }) {

  const {t} = useTranslation();

  return (
    <div className="security__badges-badge">
      <ScrollAnimation animateIn="animate__animated animate__fadeInUp" delay={delay} duration={0.5}>
        <p className="linksTxt">
          {t(i18nKeyText)}
        </p>
      </ScrollAnimation>
    </div>
  );
}

const Security = () => {

  const {t} = useTranslation();

  return (
    <section className="security" id="security">
      <div className="security__ctnr">
        <div className="security__txt-box">
          <h2 className="headerTitle u-txt-white">
            <ScrollAnimation animateIn="animate__animated animate__fadeInDown">
              <b>
              {t("securitySection.title")}
                  {/* Secure & Private */}
              </b>
            </ScrollAnimation>
          </h2>
          <br />
          <br />
            <ScrollAnimation className="paragraph u-txt-white" animateIn="animate__animated animate__fadeInUp">
          <p>
            {t("securitySection.subtitle")}
                {/* Mayan is... */}
          </p>
            </ScrollAnimation>
          <div className="security__badges">
            <Badge text='Biometrics' i18nKeyText="securitySection.pills.pill1" delay={0} />
            <Badge text='Blockchain' i18nKeyText="securitySection.pills.pill2" delay={100} />
            <Badge text='Encryption' i18nKeyText="securitySection.pills.pill3" delay={150} />
          </div>
          <div className="security__badges">
            <Badge text='Privacy by design' i18nKeyText="securitySection.pills.pill4" delay={200} />
            <Badge text='Data privacy first' i18nKeyText="securitySection.pills.pill5" delay={250} />
          </div>
        </div>
        <ScrollAnimation className="security__img-box" animateIn="animate__animated animate__fadeInUp">
          <img src={lockIcon} alt="" />
        </ScrollAnimation>
      </div>
    </section>
  );
};

export default Security;
