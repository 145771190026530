import React from "react";
import { ReactComponent as ArrowRight } from "../assets/svgs/arrow-right.svg";

export const SubmitArrowButton = ({
  id,
  text,
  wFull = false,
  type,
  name,
  form = null,
  onClick,
  disabled=false
}) => {
  return type === "submit" ? (
    <input
      name={name}
      id={id}
      type="submit"
      value={text}
      form={form}
      className={`btn ${!disabled ? "btn btn-mayan btn-mayan--green" : "btn--disabled"}`}
      style={{ maxWidth: wFull ? "100%" : "fit-content" }}
      disabled={disabled}
    />
  ) : (
    <button
      id={id}
      className={`btn ${!disabled ? "btn btn-mayan btn-mayan--green" : "btn--disabled"}`}
      style={{ maxWidth: wFull ? "100%" : "fit-content" }}
      onClick={onClick}
      disabled={disabled}
    >
      <p>
        {text}
      </p>
      <ArrowRight className="arrowRight" />
    </button>
  );
};
