import React, { useState } from "react";
import AppRouter from "./AppRouter";
import { ModalContext } from "./components/Modals/ModalContext";
ModalContext;
const Main = () => {
  const { Provider } = ModalContext;
  const [modalIsVisible, setModalIsVisible] = useState(false);
  return (
    <Provider value={{ modalIsVisible, setModalIsVisible }}>
      <AppRouter />
    </Provider>
  );
};

export default Main;
